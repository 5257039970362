import React, { Fragment, useState } from "react"
import Layout from "../templates/DefaultLayout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"

import "../styles/global.scss"
import { Container } from "react-bootstrap"
import Header from "../components/Header"
import CategoryHero from "../components/CategoryHero"
import PremiumSection from "../components/PremiumSection"
import CategoriesCard from "../components/CategoriesCard"
import Featured from "../components/Featured"
import MassageTypes from "../components/MassageTypes"
import Footer from "../components/Footer"
import ReviewsSection from "../components/ReviewsSection"

const MassagePage = location => {
  const [userLocation, setUserLocation] = useState(
    location &&
      location.location &&
      location.location.state &&
      location.location.state.location
      ? location.location.state.location
      : ""
  )
  const data = useStaticQuery(graphql`
    query PageQuery {
      premium: allContentfulPractitioners(
        limit: 3
        filter: { isPremiumHome: { eq: true } }
      ) {
        edges {
          node {
            name
            logo {
              fluid {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            mainImage {
              fluid {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            website
            address
            massageTypes {
              shortName
            }
            services {
            price
            time
            link
            massageTypes {
              name
            }
          }
          }
        }
      }
      massageType: allContentfulMassageType {
        edges {
          node {
            name
            shortName
            description {
              description
            }
            slug
            image {
              fluid {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
        }
      }
      all: allContentfulPractitioners(limit: 75) {
        nodes {
          name
          website
          slug
          premiumPractitioner
          services {
            price
            time
            link
            massageTypes {
              name
            }
          }
          logo {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          mainImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          location {
            lat
            lon
          }
          address
          massageTypes {
            shortName
            name
          }
          postcode
          serviceCards
          price
          durationMin {
            minute
          }
        }
      }
      reviews: allContentfulReview(
        sort: { fields: createdAt, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            address
            description {
              description
            }
            logo {
              fluid {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            image {
              fluid {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            name
            website
            rating
          }
        }
      }
    }
  `)
  return (
    <Fragment>
      <SEO
        title=" Find & Compare The Best Massage in Bristol -  Massages Bristol"
        description="Looking for a Massage in Bristol? Find Deep tissue massage, Sports massage & loads of massage deals in Bristol for 2021."
      />
      <Container>
        <SEO
          title={`Bristol - Massages Bristol`}
          description={`Find the best Massages Bristol. Compare the best massage deals in bristol and book a relaxing massage today.`}
        />
        <Header />
        <CategoryHero title={"All Massages"} />
        <PremiumSection data={data.premium} page="home" />
        <CategoriesCard title={"All Massages"} />
        <Featured
          data={data.all}
          title={"All Massages"}
          location={userLocation}
          setLocation={setUserLocation}
        />
        <ReviewsSection data={data.reviews} />
        <MassageTypes data={data.massageType} />
        <Footer />
      </Container>
    </Fragment>
  )
}

export default MassagePage
