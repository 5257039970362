import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const CategoriesCard = ({ title }) => {
  return (
    <Container>
      <h2>Are you looking for a different type of massage?</h2>
      <div className="cat-btns">
        {title !== "Deep Tissue Massage" ? (
          <Link className="cat-btn deep" to="/deep-tissue-massage/">
            Deep Tissue
          </Link>
        ) : null}
        {title !== "Swedish Massage" ? (
          <Link className="cat-btn swedish" to="/swedish-massage/">
            Swedish
          </Link>
        ) : null}
        {title !== "Sports Massage" ? (
          <Link className="cat-btn sports" to="/sports-massage/">
            Sports
          </Link>
        ) : null}
        {title !== "Thai Massage" ? (
          <Link className="cat-btn thai" to="/thai-massage/">
            Thai
          </Link>
        ) : null}
        {title !== "Couples Massage" ? (
          <Link className="cat-btn couples" to="/couples-massage/">
            Couples
          </Link>
        ) : null}
        {title !== "Hot Stone Massage" ? (
          <Link className="cat-btn hot" to="/hot-stone-massage/">
            Hot Stone
          </Link>
        ) : null}
      </div>
      <div className="leaf-yellow" />
    </Container>
  )
}

export default CategoriesCard

const Container = styled.div`
  position: relative;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 10px;
  background-color: #1d2b3f;
  padding: 2em;
  margin-bottom: 6em;
  margin-top: 6em;
  h2 {
    line-height: 1.4em;
    color: #fff;
    font-family: "Vidaloka", serif;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 49px;
    font-weight: 400;
    max-width: 360px;
    margin-right: 2em;
  }
  .cat-btns {
    display: flex;
    max-width: 500px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .cat-btn {
    padding: 15px 20px;
    letter-spacing: 2px;
    transition: all 0.4s;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    border-radius: 100px;
    text-transform: uppercase;
    margin: 0.5em;
    color: #333;
    white-space: nowrap;
    &:hover {
      filter: brightness(105%);
    }
  }
  .leaf-yellow {
    position: absolute;
    width: 200px;
    height: 200px;
    bottom: -0.25em;
    right: 1.25em;
    background-image: url(${require("../images/leaf-yellow.svg")});
    background-position: center;
    background-size: cover;
  }
  @media only screen and (max-width: 1140px) {
    .leaf-yellow {
      width: 100px;
      height: 100px;
      bottom: -3em;
      right: 0em;
    }
  }
  @media only screen and (max-width: 840px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 2em 1em;
    h2 {
      font-size: 24px !important;
      line-height: 1.2em;
      max-width: unset;
      margin-bottom: 1em;
    }
    .cat-btns {
      max-width: unset;
    }
  }
`
