import React, { useState } from "react"
import styled from "styled-components"
import Header from "../components/Header"
import Footer from "../components/Footer"
import HomeHero from "../components/HomeHero"
import PremiumSection from "../components/PremiumSection"
import DescriptionSection from "../components/DescriptionSection"
import Featured from "../components/Featured"
import MassageTypes from "../components/MassageTypes"
import ReviewsSection from "../components/ReviewsSection"
import Questions from "../components/Questions"
const Container = styled.div``
const DefaultLayout = ({ data }) => {
  const [location, setLocation] = useState("")

  function getLocationByPostcode() {
    // Return Lat and Long of postcode by Mapbox API Call
    let apiAddress = "/api/mapbox"
    apiAddress += "?postcode=" + location // TODO add validation for postcode"
    return fetch(apiAddress).then(res => res.json())
  }

  return (
    <Container>
      <Header />
      <HomeHero
        title="Massage"
        location={location}
        setLocation={setLocation}
        categories={data.massageType}
      />
      <PremiumSection data={data.premium} page="home" />
      <ReviewsSection data={data.reviews} />
      <DescriptionSection data={data.descriptionText} />
      <MassageTypes data={data.massageType} />
      <Questions data={data.questions} />
      <Footer />
    </Container>
  )
}

export default DefaultLayout
