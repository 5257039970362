import React, { useState } from "react"
import styled from "styled-components"

const CategoryOptions = ({ cats, setSelectedCategory }) => {
  const [categorie, setCates] = useState(cats)
  let newCats

  const handleChangedSelect = e => {
    setSelectedCategory(e.target.value)
  }
  return (
    <Container className="select-wrapper">
      <select className="content" onChange={handleChangedSelect}>
        <option value="0">Select Massage Type:</option>
        <option value="0">All Massages</option>
        {cats.edges.map(category => (
          <option value={category.node.slug}>{category.node.name}</option>
        ))}
      </select>
    </Container>
  )
}

export default CategoryOptions

const Container = styled.div`
  select {
    padding: 15px 20px;
    outline: none;
    font-size: 14px;
    color: #333;
    border: none;
    border-radius: 7px;
    margin: 7px 3px;
    font-weight: 700;
  }
`
