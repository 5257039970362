import React, { useState } from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import CategoryOptions from "./Selects/CategoryOptions"

const HomeHero = ({ title, location, setLocation, categories }) => {
  const [selectedCategory, setSelectedCategory] = useState("0")
  const handleSubmit = e => {
    e.preventDefault()
    if (selectedCategory == "0") {
      return navigate("all-massages", { state: { location } })
    }

    return navigate(selectedCategory, { state: { location } })
  }
  const data = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "hero-1.webp" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "hero-2.webp" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img3: file(relativePath: { eq: "Massage Oils.webp" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container>
      <div className="bg-color" />
      <div className="dotted-pattern" />
      <Img fluid={data.img1.childImageSharp.fluid} className="img1" />
      <Img fluid={data.img2.childImageSharp.fluid} className="img2" />
      <Img fluid={data.img3.childImageSharp.fluid} className="img3" />
      <div className="stamp-logo" />
      <div className="leaf-white" />
      <div className="leaf-navy" />
      <div className="hero-content">
        <h1>
          Massage Bristol -
          <br /> Find Massage Services
          <br className="br" /> in Bristol
        </h1>
        <p>
          Find and compare <strong>Massages in Bristol</strong>. Our 100% free
          services helps you search by type and area to find the best massage
          for you.
          <br />
          <br />
          Simply select from the list of massage types below or type in your
          postcode to start your booking now.
        </p>
      </div>
      <div className="cat-btns-wrp">
        <h2>What type of massage are you looking for?</h2>
        <div className="cat-btns">
          <Link className="cat-btn deep" to="/deep-tissue-massage/">
            Deep Tissue
          </Link>
          <Link className="cat-btn swedish" to="/swedish-massage/">
            Swedish
          </Link>
          <Link className="cat-btn sports" to="/sports-massage/">
            Sports
          </Link>
          <Link className="cat-btn thai" to="/thai-massage/">
            Thai
          </Link>
          <Link className="cat-btn couples" to="/couples-massage/">
            Couples
          </Link>
          <Link className="cat-btn hot" to="/hot-stone-massage/">
            Hot Stone
          </Link>
        </div>
      </div>
      <div className="search-btn-bg">
        <h2>
          Enter your postcode below to find Massage Practitioners near you...
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="location-input input-wrapper">
            <div className="flex-container">
              <div className="postcode-form">
                <div className="postcode-input">
                  <input
                    type="text"
                    name="location"
                    placeholder="Postcode"
                    value={location}
                    onChange={e => {
                      const val = e.target.value
                      setLocation(val)
                    }}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"></path>
                  </svg>
                </div>
                <CategoryOptions
                  cats={categories}
                  setSelectedCategory={setSelectedCategory}
                />
                <input type="submit" value="Search" className="submit-btn" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="leaf-yellow" />
    </Container>
  )
}

export default HomeHero

const Container = styled.div`
  padding: 5em 10px 0em 10px;
  position: relative;
  max-width: 1500px;
  margin: auto;
  margin-bottom: 3em;
  .hero-content {
    position: absolute;
    top: 11.5em;
    right: 10em;
  }
  .postcode-input {
    position: relative;
  }
  .postcode-form {
    display: inherit;
    margin: 0 auto;
  }
  select {
    height: 46px;
  }
  h1 {
    font-size: 50px;
    margin-bottom: 0.4em;
    line-height: 1em;
    font-weight: 400;
    font-family: "Vidaloka", serif;
    color: #1d2b3f;
  }
  h2 {
    color: #ffffff;
    font-family: "Vidaloka", serif;
    font-size: 24px;
    letter-spacing: 0.88px;
    font-weight: 400;
    margin-bottom: 0.75em;
    margin-top: 5px;
  }
  p {
    width: 515px;
    color: #000000;
    font-family: "Karla";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
  }
  .dotted-pattern {
    position: absolute;
    top: 0;
    left: 18%;
    width: 45%;
    max-width: 700px;
    height: 53.5em;
    background-image: url(${require("../images/dotted-pattern.svg")});
  }
  .bg-color {
    position: absolute;
    top: 0;
    left: 18%;
    width: 82%;
    height: 53.5em;
    background-color: #feede2;
  }
  .cat-btns-wrp {
    position: absolute;
    bottom: 10.5em;
    right: 1.5em;
    border-radius: 10px;
    background-color: #1d2b3f;
    padding: 1.25em;
    padding-right: 5em;
    width: 100%;
    max-width: 950px;
    text-align: center;
  }
  .search-btn-bg {
    z-index: 2;
    position: absolute;
    bottom: 0.5em;
    right: 1.5em;
    width: calc(100% - 3em);
    text-align: center;
    max-width: 950px;
    .submit-btn {
      background: #bdf9d3;
      padding: 15px 20px !important;
    }
  }
  .cat-btns {
    display: flex;
    justify-content: space-between;
  }
  .cat-btn {
    padding: 15px 20px;
    letter-spacing: 2px;
    transition: all 0.4s;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    border-radius: 100px;
    text-transform: uppercase;
    margin: 0.5em;
    color: #333;
    white-space: nowrap;
    &:hover {
      filter: brightness(105%);
    }
  }
  .img1 {
    width: 280px;
    position: absolute;
    top: 3.5em;
    left: 1.65em;
    height: 425px;
  }
  .img2 {
    width: 480px;
    height: 320px;
    position: absolute;
    top: -10em;
    left: 11.5em;
  }
  .img3 {
    width: 340px;
    height: 225px;
    position: absolute;
    top: -3.5em;
    left: 1.65em;
    z-index: 2;
  }
  .stamp-logo {
    position: absolute;
    top: 15em;
    left: 13.5em;
    width: 204px;
    height: 204px;
    background-image: url(${require("../images/stamp.svg")});
  }
  .leaf-white {
    position: absolute;
    width: 255px;
    height: 270px;
    top: 4.5em;
    right: 1.5em;
    background-image: url(${require("../images/leaf-white.svg")});
  }
  .leaf-navy {
    position: absolute;
    width: 149px;
    height: 165px;
    bottom: 12.25em;
    left: 3.5em;
    z-index: 2;
    background-image: url(${require("../images/leaf-navy.svg")});
  }
  .leaf-yellow {
    position: absolute;
    width: 100px;
    height: 100px;
    bottom: 8.5em;
    right: 1.25em;
    background-image: url(${require("../images/leaf-yellow.svg")});
    z-index: 3;
  }
  .search-btn-bg {
    border-radius: 10px;
    background-color: #1d2b3f;
    h2 {
      color: #ffffff;
      font-family: "Vidaloka", serif;
      font-size: 24px;
      letter-spacing: 0.88px;
      font-weight: 400;
      margin-bottom: 0.75em;
      margin-top: 5px;
      padding: 1em 0.5em 0 0.5em;
    }
    input {
      padding: 15px 20px;
      outline: none;
      font-size: 14px;
      color: #333;
      border: none;
      border-radius: 7px;
      margin: 7px 3px;
      font-weight: 700;
    }

    .input-wrapper {
      position: relative;
      width: 100%;
      padding: 0 1em 1em 0.3em;
      .flex-container {
        display: flex;;
      }
      svg {
        position: absolute;
        transform: translate(-30px, 23px);
        fill: #444;
        cursor: pointer;
      }
      &:hover {
        nav {
          width: 250px;
          transform: translateY(0px);
          height: 100%;
          opacity: 1;
        }
        .select-wrapper {
          z-index: 25;
        }
      }
    }
    .location-input {
      input {
        padding-left: 30px;
      }
      svg {
        left: 1em;
        transform: translate(0, 24px);
        position: absolute;
      }
    }
  }
  @media only screen and (max-width: 1390px) {
    .bg-color {
      left: 250px;
      width: calc(100% - 250px);
    }
    .dotted-pattern {
      left: 250px;
      width: 40%;
      max-width: 700px;
    }
    .hero-content {
      right: 4em;
    }
    .cat-btns-wrp,
    .search-btn-bg {
      max-width: 850px;
    }
  }
  @media only screen and (max-width: 1270px) {
    .img2,
    .img3,
    .leaf-navy {
      visibility: hidden;
    }
    .cat-btns-wrp,
    .search-btn-bg {
      max-width: unset;
      width: calc(100% - 3em);
    }
    .hero-content {
      right: unset;
      left: 40%;
      top: 15em;
    }
    .img1,
    .stamp-logo {
      transform: translateY(2em);
    }
  }
  @media only screen and (max-width: 900px) {
    .hero-content {
      max-width: 500px;
      left: 45%;
    }
    h1 {
      font-size: 36px;
    }
    p {
      font-size: 16px;
      width: 400px;
    }
    h2 {
      font-size: 20px;
    }
    .cat-btns-wrp,
    search-btn-bg {
      padding-right: 3.7em;
    }
    .cat-btn {
      margin: 0.5em 0.25em;
    }
    .bg-color,
    .dotted-pattern {
      min-height: unset;
    }
  }
  @media only screen and (max-width: 840px) {
    .flex-container, .postcode-form {
      flex-direction: column;
    }
    select {
      width: 100%;
      margin: 7px 0;
    }
    .bg-color {
      left: 0;
      top: 3.7em;
      width: 100%;
      max-width: unset;
      z-index: -1;
      height: 24em;
    }
    .dotted-pattern {
      left: 0;
      top: 3.7em;
      width: 100%;
      max-width: unset;
      z-index: -1;
      height: 25em;
    }
    .img1,
    .img2,
    .img3,
    .leaf-navy,
    .stamp-logo,
    .leaf-yellow {
      display: none;
    }
    .hero-content,
    .cat-btns-wrp,
    .search-btn-bg {
      position: initial;
      top: unset;
      left: unset;
      right: unset;
      margin-left: 2em;
    }
    .leaf-white {
      width: 120px;
      background-position: center;
      background-size: cover;
      height: 130px;
      top: 6em;
      right: 1.5em;
      z-index: -1;
    }
    p {
      line-height: 1.5em;
    }
    .hero-content {
      margin-top: 2em;
    }
    .cat-btns {
      flex-wrap: wrap;
    }
    .cat-btns-wrp,
    .search-btn-bg {
      padding-right: 1.25em;
      margin-top: 2em;
    }
  }
  @media only screen and (max-width: 550px) {
    h1 {
      max-width: 300px;
      line-height: 1.2em;
    }
    .br {
      display: none;
    }
    p {
      width: unset;
      padding-right: 1em;
    }
    .hero-content,
    .cat-btns-wrp,
    .search-btn-bg {
      margin-left: 5px;
      margin-right: 5px;
      width: calc(100% - 10px);
    }
    .bg-color {
      height: 27em;
    }
  }
  @media only screen and (max-width: 430px) {
    h1 {
      font-size: 28px;
      max-width: 250px;
    }
    .leaf-white {
      width: 100px;
      height: 110px;
    }
    .cat-btns-wrp {
    }
    }
  }
`
