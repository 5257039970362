import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const CategoryHero = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "hero-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "hero-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container>
      <div className={`${title?.toLowerCase().replace(" ", "-")} bg-color`} />
      <div className="dotted-pattern" />
      <div className="hero-content">
        <h1>{title} Bristol</h1>
        <h2>Find The Best {title}? & Spa Offers in Bristol</h2>
        <p>
          Here at Massages Bristol we aim to bring you the best massages and spa
          services Bristol has to offer. Compare the best Massage providers in
          Bristol below and book a massage today.
        </p>
      </div>
      <div className="imgs">
        <Img fluid={data.img1.childImageSharp.fluid} className="img1" />
        <Img fluid={data.img2.childImageSharp.fluid} className="img2" />
      </div>
      <div className="leaf-white" />
      <div className="leaf-navy" />
    </Container>
  )
}

export default CategoryHero

const Container = styled.div`
  padding: 5em 1em 2.5em 1em;
  position: relative;
  max-width: 1500px;
  margin: auto;
  margin-bottom: 2.5em;
  display: flex;
  justify-content: space-around;
  align-items: center;

  h1 {
    font-size: 50px;
    margin-bottom: 0.5em;
    line-height: 1em;
    font-weight: 400;
    font-family: "Vidaloka", serif;
    color: #1d2b3f;
  }
  h2 {
    font-size: 30px;
    margin-bottom: 0.8em;
    line-height: 1.3em;
    font-weight: 400;
    max-width: 700px;
    font-family: "Vidaloka", serif;
    color: #1d2b3f;
  }
  p {
    max-width: 700px;
    color: #000000;
    font-family: "Karla";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
  }
  .dotted-pattern {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(${require("../images/dotted-pattern.svg")});
  }
  .bg-color {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .deep-tissue {
    background: linear-gradient(90deg, #feede2 0%, #f7e4bf 100%);
  }
  .couples-massage {
    background: linear-gradient(90deg, #feede2 0%, #fccfd6 100%);
  }
  .sports-massage {
    background: linear-gradient(90deg, #feede2 0%, #bdf9d3 100%);
  }
  .thai-massage {
    background: linear-gradient(90deg, #feede2 0%, #c1f8ff 100%);
  }
  .hot-stone {
    background: linear-gradient(90deg, #feede2 0%, #fcc486 100%);
  }
  .swedish-massage {
    background: linear-gradient(90deg, #feede2 0%, #d2cdfc 100%);
  }
  .all-massages {
    background: linear-gradient(90deg, #feede2 0%, #f7e4bf 100%);
  }
  .img1 {
    position: absolute;
    width: 320px;
    top: 5.5em;
    left: 0em;
    height: 220px;
  }
  .img2 {
    width: 205px;
    height: 205px;
    position: relative;
    bottom: -4em;
    transform: translateX(14em);
  }
  .leaf-white {
    position: absolute;
    width: 170px;
    height: 180px;
    top: 9em;
    right: 1em;
    background-position: center;
    background-size: cover;
    background-image: url(${require("../images/leaf-white.svg")});
  }
  .leaf-navy {
    position: absolute;
    width: 149px;
    height: 165px;
    bottom: -1.5em;
    left: 1em;
    z-index: 2;
    transform: rotate(-80deg);
    background-image: url(${require("../images/leaf-navy.svg")});
  }
  @media only screen and (max-width: 1430px) {
    .img1 {
      left: -5em;
    }
    .img2 {
      transform: translateX(11em);
    }
  }
  @media only screen and (max-width: 1240px) {
    padding-top: 8em;
    .img1,
    .img2,
    .leaf-navy {
      display: none;
    }
  }
  @media only screen and (max-width: 920px) {
    .leaf-white {
      width: 90px;
      height: 100px;
      top: 4.5em;
      right: 0.5em;
    }
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
      font-weight: 700;
    }
  }
  @media only screen and (max-width: 500px) {
    h1 {
      max-width: 270px;
      line-height: 1.4em;
    }
  }
`
